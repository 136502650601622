import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div className="container--padded pb-resp-16-24 pt-resp-32-56">

        <div className=" max-w-3xl mx-auto text-center">

          <StaticImage src="../images/404.jpg" alt="404" />
          <p className="text-lg mt-resp-5-8 leading-snug">The page you are looking for might have been removed had its name changed or is temporarily unavailable</p>
          <div className="action mt-resp-5-8">
            <Link to="/" className="btn btn--large btn--primary">
             Go To Homepage
            </Link>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
